module.exports = {
  locale: "ru-RU",
  messages: {
    "email": "Неверный формат почты",
    "required": "Это поле обязательное",
    "more.today": "Не позднее сегодняшнего дня",
    "more.start": "Не позднее даты начала",
    "max": "Слишком большое сообщение"
  }
}
