import React, { useState } from "react"
import { ValidatorContext } from "../context.js"
import { useIntl } from "gatsby-plugin-react-intl"

function Form({ children,
                validate = false,
                filter = item => item === 'error' || item === null,
                ...rest }){

  const props = {
    ...rest,
    noValidate: true,
    autoComplete: "off"
  }

  const [ states, setGroupState ] = useState({})
  const [formFilter, setFilter] = useState({ filter })
  const intl = useIntl();

  const context = {
    states,
    setGroupState,
    validate,
    filter: formFilter.filter,
    setFilter,
    locale: intl.locale
  }

  return (
    <form { ...props }>
      <ValidatorContext.Provider value={context}>
        { children }
      </ValidatorContext.Provider>
    </form>
  )
}

export default Form;
