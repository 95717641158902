module.exports = {
  locale: "zh",
  messages: {
    "email": "郵件格式無效",
    "required": "此字段是必需的",
    "more.today": "不遲於今天",
    "more.start": "不遲於開始日期",
    "max": "信息太大了"
  }
}
