const Locales = require('./lang.js');

class Validator{
  constructor(rules, value, locale) {
    this.rules = rules;
    this.value = value;
    this.locales = new Locales(locale)
  }
  validate() {
    const errors = this.rules.map(rule => {
      const payload = rule.passes(null, this.value);
      if(!payload) return this.locales.getMessage(rule.message());
      return null;
    }).filter(item => item !== null);
    if(errors.length) return [ false, errors.shift() ];
    return [ true, null ];
  }
}

export default Validator;
