module.exports = {
  locale: "en",
  messages: {
    "email": "Incorrect email format",
    "required": "This field is required",
    "more.today": "No later than today",
    "more.start": "Not later than start date",
    "max": "The message is too big"
  }
}
