const locales = [
  require("./locales/ru-RU.js"),
  require("./locales/en.js"),
  require("./locales/zh.js")
]

class Locales {
  constructor(lang) {
    this.lang = lang
    this.locales = locales
  }

  getMessage(message) {
    // console.log(this.locales)
    return this.locales.find(({ locale }) => locale === this.lang).messages[message] || message;
  }
}

module.exports = Locales
