import React, { useContext, useEffect, useRef, useState } from "react"
import clsx from "clsx"
import { makeComponentStyles } from "../../mixins.js"
import { FormGroupContext, ValidatorContext } from "../context.js"
import Validator from "@validator"
import InputMask from 'react-input-mask';

const styles = ({ palette }) => ({
  root: {
    padding: "10px 9px",
    border: "solid 1px " + palette.text.primary,
    borderRadius: ".25rem",
    outline: "none",
    width: "100%",
    transition: "all .25s ease"
  },
  form__control_error: {
    color: palette.text.warning,
    borderColor: palette.text.warning
  },
  form__control_success: {
    color: palette.text.success,
    borderColor: palette.text.success
  }
})

const Input = (props) => (<input { ...props }/>)
const TextArea = ({ type, value, ...props}) => (<textarea { ...props}>{value}</textarea>)
const Select = ({ type, value, children, ...props}) => (<select { ...props}>{ children }</select>)

function Control({ type = "text",
                   value,
                   onChange,
                   placeholder,
                   validations = [],
                   className,
                   children,
                   parentRef,
                   mask = false,
                   ...rest }) {
  const { root, form__control_error, form__control_success } = makeComponentStyles(styles)

  const { setErrors, setErrorType, ...state } = useContext(FormGroupContext);
  const { locale } = useContext(ValidatorContext);

  const [InputValue, setInputValue] = useState(value);

  className = clsx(root, {
    [form__control_error]: state.status === "error",
    [form__control_success]: state.status === "success"
  }, className)

  function handleValidations(event) {
    const validator = new Validator(validations, event.target.value, locale);
    const [isSuccess, error] = validator.validate();
    if(!isSuccess) {
      setErrorType('basic');
      return setErrors(error);
    }
    return setErrors(null)
  }

  const handleMaskValueChange = e => {
    setInputValue(e.target.value);
  }

  const multipleOnChange = e => {
    if(mask) handleMaskValueChange(e);
    onChange(e);
    handleValidations(e);
  }

  const props = {
    type, value, onChange: multipleOnChange, className, placeholder, ref: parentRef, ...rest
  }

  if(mask) {
    const {value, placeholder, ...maskProps} = props;
    return (<InputMask mask={mask} value={InputValue} {...maskProps} />)
  }

  switch (props.type){
    case "textarea": return (<TextArea {...props} />);
    case "select": return (<Select {...props}>{ children }</Select>)
    default: return (<Input {...props} />);
  }
}


export default Control
