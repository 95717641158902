import React, { useContext } from "react"
import { makeComponentStyles } from "@components/mixins.js"
import clsx from "clsx"
import { FormGroupContext } from "../context.js"
import { FormLabel } from "@material-ui/core"

const styles = ({ palette }) => ({
  root: {
    marginBottom: "5px",
    display: "block",
    transition: "color .25s ease"
  },
  label_error: {
    color: palette.text.warning,
  },
  label_success: {
    color: palette.text.success
  }
})


function Label({ children }){
  const { root, label_error, label_success } = makeComponentStyles(styles);
  const { status } = useContext(FormGroupContext);
  const className = clsx(root, {
    [label_error]: status === 'error',
    [label_success]: status === 'success'
  })
  return (
    <FormLabel
      className={className}
    >{ children }</FormLabel>
  )
}

export default Label;
