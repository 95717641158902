import React, { useContext } from "react"
import { makeComponentStyles } from "@components/mixins.js"
import clsx from "clsx"
import { FormGroupContext } from "../context.js"
import { Typography } from "@material-ui/core"


const styles = ({ palette }) => ({
  root: {
    display: "block",
    marginTop: "5px",
    fontSize: "14px",
    transition: "color .25s ease"
  },
  feedback_error: {
    color: palette.text.warning,
  },
  feedback_success: {
    color: palette.text.success
  }
})

function FormFeedback() {
  const {
    root,
    feedback_error,
    feedback_success
  } = makeComponentStyles(styles);

  const { status, message } = useContext(FormGroupContext);
  const className = clsx(root, {
    [feedback_error]: status === 'error',
    [feedback_success]: status === 'success',
  })

  return (
    <Typography variant="caption" className={className}>
      { status ? message : "" }
    </Typography>

  )
}

export default FormFeedback;
