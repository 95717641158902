import clsx from "clsx"
import React, { useContext, useEffect, useState } from "react"
import { makeComponentStyles } from "../../mixins.js"
import { FormGroupContext, ValidatorContext } from "../context.js"

const styles = (_) => ({
  root: {
    width: "100%",
    marginBottom: "15px"
  }
})

const groupStates = {
  error: (message) => ({ status: "error", message }),
  success: (message) => ({ status: "success", message }),
  basic: () => ({ status: null, message: null })
}

const scenarios = {
  'basic': item => item === 'error' || item === null,
  'request-error': item => item === null
}

function FormGroup({ children, name = null, requestError = false, className}) {
  const classes = makeComponentStyles(styles);
  const [error, setErrors] = useState(false);
  const [errorType, setErrorType] = useState("basic");
  const [state, setState] = useState(groupStates.basic());
  const { validate, setGroupState, setFilter } = useContext(ValidatorContext);

  useEffect(() => {
    if (requestError && validate) {
      setErrors(requestError);
      requestError = false;
      setErrorType("request-error")
    }
  }, [requestError]);

  useEffect(() => {
    if(validate){
      if (error) setState(groupStates.error(error))
      else if (error === null) setState(groupStates.success(""))
      // setFilter({ filter: scenarios[errorType] })
    }
  }, [error]);

  useEffect(() => {
    setGroupState(prev => ({
      ...prev,
      [name]: state
    }))
  }, [state])

  const context = {
    ...state, setErrors, setErrorType
  }
  return (
    <div
      className={
        clsx(classes.root, className)
      }
    >
      <FormGroupContext.Provider value={context}>
        {children}
      </FormGroupContext.Provider>
    </div>
  )
}

export default FormGroup
